
*{
  font-family: 'Roboto', sans-serif;
  
}
body {
  margin: 0;

  overflow: hidden;
  height: 100vh; 
}


.navbar{
  border-bottom: 1px solid #DEDEDE;
  z-index: 9999;
  }
  .navbar-brand{
    color: #264ECA!important;
    font-weight: bold;
    height: 37px;
   
    font-family: 'Righteous', sans-serif;
    
  }
  .navbar-brand:hover{
    color:#264ECA
  }

.typing-dots {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 10px;
}

.typing-dots::before,
.typing-dots::after,
.typing-dots span {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 50%;
  animation: typing 1.5s infinite;
}

.typing-dots::before {
  left: 0;
  animation-delay: 0s;
}

.typing-dots span {
  left: 18px;
  animation-delay: 0.3s;
}

.typing-dots::after {
  left: 36px;
  animation-delay: 0.6s;
}
.main__app{

    display: flex;
    justify-content: center; 
   font-size: 12px;
 height: 83vh;
 

}
.chat_card {
  overflow-y: auto;
  height: calc(100vh - 235px);
  padding: 10px;
  box-sizing: border-box;
}
.card_body_noData{
  height: fit-content !important;
  }
.user_text {
  background-color: #244BC5;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 13px ;
  text-align: justify;
  color: #303030;
}
.user_replay {
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 13px;
  text-align: justify;
}
.card_body{
  height: 81vh;
}
.chat-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding:0px 15px 10px 15px;
  color: #000;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #dedede;
  z-index: 10;
}
.form-container {
  position: relative;
}
.form-container h6{
  font-weight: 600;
}
.form-control-wrapper {
  display: flex;
  flex-direction: column;
}
.AI_Bot{
  font-size: 11px;
  color: #534D64;
  font-weight: 550;
  margin-bottom: 5px;


}
.send_img1 {
  position: absolute;
  bottom: 7px;
  left: 97%;
  transform: translateX(-50%);
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-color: #264ECA;
  padding: 4px;
  border-radius: 15%;
}

.chat-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  margin-top: 12px;
}
.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

input.form-control {
  border-radius: 8px;
  padding-right: 50px;
  height: 45px;
  font-size: 14px;
  background: #EFF6FC;
}
.footer{
  background: #F8FCFF;
  font-size: 13px;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}



@media screen and (max-width: 768px) {
  .chat_card {
    height: calc(100vh - 230px); 
  }

  .chat-header {
    font-size: 14px; 
    padding: 8px; 
  }
  .send_img1 {
    left: 95%;
  }
  .user_text,
  .user_replay {
    font-size: 13px; 
  }
}

@media screen and (max-width: 480px) {
  body{
    overflow-y: scroll;
    height: 100vh;
  }
  .chat_card {
    height: calc(100vh - 190px); 
  }
.main__app{
  height: 88vh;
}
.card_body{height: 89vh;}
  .chat-header {
    font-size: 11px; 
    padding: 6px; 
  }
  .send_img1 {
    left: 94%;
  }
  .user_text,
  .user_replay {
    font-size: 12px; 
  }
  .navbar-brand{
    color: #264ECA!important;
    font-weight: bold;
    height: 28px;
   padding-top: 0px!important;
   padding-bottom: 0px!important;
    font-family: 'Righteous', sans-serif;
    
  }
  .footer p{
    margin-bottom: 0px!important;
  }
}
@media screen and  (min-width: 1443px) and (max-width: 1920px) {
  .chat_card {
    overflow-y: auto;
    height: calc(100vh - 280px); 
    padding: 10px;
    box-sizing: border-box;
  }
  .main__app{ font-size: 16px;    height: 86vh;}
}
@media screen and  (min-width: 1920px) and (max-width: 2560px) {
  .chat_card {
    overflow-y: auto;
    height: calc(100vh - 311px); 
    padding: 10px;
    box-sizing: border-box;
  }
  .main__app{ font-size: 18px;    height: 86vh;}
  .main__app h6{
    font-size: 22px;
  }
  .navbar-brand {
    height: 47px;
  }
  .footer{
 
    font-size: 16px;
  }
  .chat-header{
    font-size: 18px;
    
    
  }
  .input.form-control{
    font-size: 18px;
  }
  .AI_Bot{
    font-size: 14px;
  
  }
}