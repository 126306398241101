.App {
  text-align: center;
  background: #F8FCFF;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes typing {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
